












import Vue from 'vue'
import NewUiNotifications from '@/components/NewUIComponents/NewUiNotifications.vue'

export default Vue.extend({
  name: 'LoginLayout',
  components: { NewUiNotifications }
})
