
















import Vue from 'vue'

export default Vue.extend({
  name: 'ARMaintenanceModePage',
  computed: {
    landingUrl (): string {
      return process.env.VUE_APP_LANDING_URL
    }
  }
})
