import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import store from '../store'
import auth from '@/router/middleware/auth'
import emailConfirmed from '@/router/middleware/emailConfirmed'
import checkAdAccount from '@/router/middleware/checkAdAccount'
import checkPermissions from '@/router/middleware/checkPermissions'
import checkUses from '@/router/middleware/checkUses'
import middlewarePipeline from '@/router/middlewarePipeline'
import redirectAuthorized from '@/router/middleware/redirectAuthorized'
import checkDeletionAccount from '@/router/middleware/checkDeletionAccount'
import maintenanceMode from '@/router/middleware/maintenanceMode'
import {
    RULES_PERMISSION,
    ADS_MANAGER_PERMISSION,
    INTERESTS_PERMISSION,
    POST_BOOSTING_PERMISSION
} from '@/constants/UserPermissions'
import {
    FACEBOOK_SERVICE,
    GOOGLE_SERVICE,
    LINKEDIN_SERVICE,
    SNAPCHAT_SERVICE,
    TWITTER_SERVICE,
    TIKTOK_SERVICE
} from '@/constants/FbAutomatedRule'
import { AFFILIATE_ROUTES } from '@/constants/Affiliate'
import redirectRestore from '@/router/middleware/redirectRestore'
import { Store } from 'vuex'
import NotFound from '@/views/NotFound.vue'
import WithoutAdAccount from '@/views/Strategies/WithoutAdAccount.vue'
import FirstPage from '@/views/FirstPage.vue'
import MaintenanceModePage from '@/views/MaintenanceModePage.vue'
import ARMaintenanceModePage from '@/views/ARMaintenanceModePage.vue'

Vue.use(VueRouter)

const postBoostingMiddlewares = [auth, emailConfirmed, checkUses]
const metaPostBoosting = {
    title: 'Auto Post Boosting',
    layout: 'main',
    middleware: postBoostingMiddlewares,
    permissionCheckKey: POST_BOOSTING_PERMISSION
}

const MaintenanceModeRoutes: Array<RouteConfig> = [
    {
        path: '/maintenance',
        name: 'MaintenanceModePage',
        component: MaintenanceModePage,
        meta: {
            title: 'ConvertBomb',
            layout: 'empty'
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['appConfig/getMaintenanceMode']) {
                return next({ name: 'Profile' })
            }
            return next()
        }
    }
]

const defaultMiddlewares = [maintenanceMode]

const ARMaintenanceModeRoutes: Array<RouteConfig> = [
  {
    path: '/maintenance',
    name: 'MaintenanceModePage',
    component: ARMaintenanceModePage,
    meta: {
      title: 'Automated Rules',
      layout: 'empty'
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['appConfig/getMaintenanceMode']) {
        return next({ name: 'Profile' })
      }
      return next()
    }
  }
]

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'First',
    component: FirstPage,
    meta: {
      title: 'AutomatedRules',
      layout: 'empty'
    }
  },
  {
    path: '/trial_has_expired',
    name: 'TrialExpired',
    props: true,
    meta: {
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    component: () =>
      import(/* webpackChunkName: "YourTrialHasExpired" */ '../views/YourTrialHasExpired.vue')
  },
  {
    path: '/feature_unavailable',
    name: 'FeatureUnavailable',
    meta: {
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    component: () =>
      import(/* webpackChunkName: "YourTrialHasExpired" */ '../views/ThisFeatureIsUnavailable.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    component: () =>
      import(/* webpackChunkName: "About" */ '../views/About.vue')
  },
  {
    path: '/integrations',
    name: 'Integrations',
    meta: {
      title: 'Integrations',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    props: route => ({
      auto_connect: route.query.auto_connect === 'true',
      service: route.query.service,
      error_code: route.query.error_code,
      scroll_to: route.query.scroll,
      success: route.query.success
    }),
    component: () =>
      import(/* webpackChunkName: "ServiceIntegration" */ '../views/ServiceIntegration.vue')
  },
  {
    path: '/integrations/:service/settings',
    name: 'Settings',
    meta: {
      title: 'Integration settings',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SettingsAdServiceIntegrations" */ '../views/SettingsAdServiceIntegrations.vue'
      )
  },
  {
    path: '/integrations/:service/pages',
    name: 'Pages',
    meta: {
      title: 'Integration settings',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SettingsAdServiceIntegrations" */ '../views/SettingsAdServiceIntegrationsPages.vue'
      )
  },
  {
    path: '/restore-account',
    name: 'RestoreAccount',
    meta: {
      layout: 'main',
      middleware: [auth, emailConfirmed, redirectRestore]
    },
    component: () => import(/* webpackChunkName: "Profile" */'../views/RestoreAccount.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Profile',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    component: () => import(/* webpackChunkName: "Profile" */'../views/Profile.vue')
  },
  {
    path: '/profile/:tab',
    name: 'Profile Tab',
    meta: {
      title: 'Profile',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    props: true,
    component: () => import(/* webpackChunkName: "Profile" */'../views/Profile.vue')
  },
  ...AFFILIATE_ROUTES
    .map((route) => {
      const name = `Affiliate ${route.title}`
      return {
        path: `/profile/affiliate/${route.key}`,
        name,
        meta: {
          title: name,
          layout: 'main'
        },
        props: {
          routeKey: route.key
        },
        component: () => import('../views/ProfileAffiliateTable.vue')
      }
    }),
    {
        path: '/emails/confirm/:email/:token',
        name: 'ConfirmEmail',
        meta: {
            title: 'Email confirmation',
            layout: 'empty'
        },
        component: () => import(/* webpackChunkName: "ConfirmEmail" */ '../views/ConfirmEmail.vue')
    },
    {
        path: '/support',
        name: 'Support',
        meta: {
            title: 'Support',
            layout: 'main',
            middleware: [auth, emailConfirmed, checkDeletionAccount]
        },
        component: () => import(/* webpackChunkName: "Tickets" */ '../views/Support/Support.vue')
    },
    {
        path: '/support/ticket/new',
        name: 'SupportNewTicket',
        meta: {
            title: 'New Ticket',
            layout: 'main',
            middleware: [auth, emailConfirmed, checkDeletionAccount]
        },
        component: () =>
            import(/* webpackChunkName: "Tickets" */ '../views/Support/SupportNewTicket.vue')
    },
    {
        path: '/support/ticket/:id',
        name: 'SupportTicket',
        props: true,
        meta: {
            title: 'Ticket',
            layout: 'main',
            middleware: [auth, emailConfirmed, checkDeletionAccount]
        },
        component: () =>
            import(/* webpackChunkName: "Tickets" */ '../views/Support/SupportTicket.vue')
    },
    {
        path: '/ads-manager',
        name: 'AdsManager',
        meta: {
            title: 'Ads Manager',
            layout: 'main',
            middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
            permissionCheckKey: ADS_MANAGER_PERMISSION,
            remove_viewport: true
        },
        component: () => import(/* webpackChunkName: "adsManager" */ '../views/AdsManager.vue')
    },

  {
    path: '/ads-manager/dashboard/campaigns/:campaign_id',
    name: 'dashboard',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () => import(/* webpackChunkName: "AdsManagerDashboard" */'@/components/ADSManager/Dashboard/Dashboard.vue')
  },
  {
    path: '/ads-manager/dashboard/campaigns/:campaign_id/all-ads',
    name: 'AllAds',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () => import(/* webpackChunkName: "AllAdsTable" */'@/components/ADSManager/AllAds/AllAdsTable.vue')
  },
  {
    path: '/ads-manager/fb/new',
    name: 'NewCampaign',
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () =>
      import(/* webpackChunkName: "NewCampaign" */'../components/ADSManager/NewCampaign/NewCampaign.vue')
  },
  {
    path: '/ads-manager/fb/new/:campaign_id/campaign',
    name: 'EditCampaign',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () =>
      import(/* webpackChunkName: "NewCampaign" */'../components/ADSManager/NewCampaign/NewCampaign.vue')
  },
  {
    path: '/ads-manager/fb/new/:campaign_id/audience',
    name: 'NewCampaignAudience',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () => import(/* webpackChunkName: "Audience" */'../components/ADSManager/NewCampaign/Audience.vue')
  },
  {
    path: '/ads-manager/fb/new/:campaign_id/ad-design',
    name: 'NewCampaignAdDesign',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () => import(/* webpackChunkName: "AdDesign" */'../components/ADSManager/NewCampaign/AdDesign.vue')
  },
  {
    path: '/instant-form/success',
    name: 'InstantFormProcessEnd',
    meta: {
      title: 'Instant Forms',
      layout: 'empty',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION
    },
    component: () => import(/* webpackChunkName: "InstantFormProcessEnd" */'../components/ADSManager/NewCampaign/InstantFormProcessEnd.vue')
  },
  {
    path: '/ads-manager/fb/new/:campaign_id/budget',
    name: 'NewCampaignBudget',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () => import(/* webpackChunkName: "Budget" */'../components/ADSManager/NewCampaign/Budget.vue')
  },
  {
    path: '/ads-manager/fb/new/:campaign_id/tracking',
    name: 'NewCampaignTracking',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () => import(/* webpackChunkName: "Tracking" */'../components/ADSManager/NewCampaign/Tracking.vue')
  },
  {
    path: '/ads-manager/fb/new/:campaign_id/review',
    name: 'NewCampaignReview',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () => import(/* webpackChunkName: "Publish" */'../components/ADSManager/NewCampaign/Publish.vue')
  },
  {
    path: '/ads-manager/fb/new/:campaign_id/publish',
    name: 'CampaignPublish',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION,
      remove_viewport: true
    },
    component: () =>
      import(/* webpackChunkName: "Publishing" */'../components/ADSManager/NewCampaign/Publishing.vue')
  },
  {
    path: '/ads-manager/fb/edit/:campaign_id',
    props: true,
    meta: {
      title: 'Ads Manager',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: ADS_MANAGER_PERMISSION
    },
    component: () =>
      import(/* webpackChunkName: "Publishing" */'../components/ADSManager/EditCampaign/EditCampaign.vue'),
    children: [
      {
        path: '',
        name: 'EditPublishedCampaign',
        meta: {
          title: 'Campaign Edit',
          layout: 'main',
          middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
          permissionCheckKey: ADS_MANAGER_PERMISSION
        },
        component: () =>
          import(/* webpackChunkName: "CampaignSettings" */'../components/ADSManager/EditCampaign/EditCampaignMainSettings.vue')
      },
      {
        path: 'ad_sets/:ad_set_id',
        name: 'EditPublishedCampaignAdSet',
        meta: {
          title: 'AdSet Edit',
          layout: 'main',
          middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
          permissionCheckKey: ADS_MANAGER_PERMISSION
        },
        props: true,
        component: () =>
          import(/* webpackChunkName: "CampaignSettings" */'../components/ADSManager/EditCampaign/EditAdSet.vue')
      },
      {
        path: 'ad_sets/:ad_set_id/ads/:ad_id',
        name: 'EditPublishedCampaignAd',
        meta: {
          title: 'Ad Edit',
          layout: 'main',
          middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
          permissionCheckKey: ADS_MANAGER_PERMISSION
        },
        props: true,
        component: () =>
          import(/* webpackChunkName: "CampaignSettings" */'../components/ADSManager/EditCampaign/EditAd.vue')
      }
    ]
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: {
      title: 'FAQ',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    component: () => import(/* webpackChunkName: "Faq" */'../views/Faq.vue')
  },
  {
    path: '/post-boosting-without-connect/:service',
    name: 'PostBoostingWithoutConnect',
    props: true,
    meta: metaPostBoosting,
    component: () => import('../views/PostBoosting/PostBoostingWithoutConnect.vue')
  },
  {
    path: '/auto-post-boosting/:service',
    name: 'PostBoosting',
    meta: metaPostBoosting,
    props: true,
    component: () => import('../views/AutoPostBoosting/AutoPostBoosting.vue')
    // component: () => import('../views/PostBoosting/PostBoosting.vue')
  },
  {
    path: '/auto-post-boosting/:service/edit/:ruleId',
    name: 'EditPostBoosting',
    meta: metaPostBoosting,
    props: true,
    component: () => import('../views/AutoPostBoosting/AutoPostBoostingEdit.vue'),
    // component: () => import('../views/PostBoosting/PostBoostingNew.vue'),
    children: [
      {
        path: '/auto-post-boosting/:service/edit/:ruleId/details',
        name: 'PostBoostingEditDetails',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Details',
          name: 'Details'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingDetails.vue')
        // component: () => import('../views/PostBoosting/PostBoostingDetails.vue')
      },
      {
        path: '/auto-post-boosting/:service/edit/:ruleId/filters',
        name: 'PostBoostingEditFilters',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Filters',
          name: 'Filters'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingFilters.vue')
        // component: () => import('../views/PostBoosting/PostBoostingFilters.vue')
      },
      {
        path: '/auto-post-boosting/:service/edit/:ruleId/campaign',
        name: 'PostBoostingEditCampaign',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Campaign',
          name: 'Campaign'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingCampaign.vue')
        // component: () => import('../views/PostBoosting/PostBoostingCampaign.vue')
      },
      {
        path: '/auto-post-boosting/:service/edit/:ruleId/ad-set',
        name: 'PostBoostingEditAdSet',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Ad Set',
          name: 'Ad Set'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingAdSet.vue')
        // component: () => import('../views/PostBoosting/PostBoostingAdSet.vue')
      },
      {
        path: '/auto-post-boosting/:service/edit/:ruleId/ad',
        name: 'PostBoostingEditAd',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Ad',
          name: 'Ad'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingAd.vue')
        // component: () => import('../views/PostBoosting/PostBoostingAd.vue')
      }
    ]
  },
  {
    path: '/auto-post-boosting/:service/new/details',
    name: 'PostBoostingNew',
    meta: {
      ...metaPostBoosting,
      title: 'Auto Post Boosting Details'
    },
    props: true,
    component: () => import('../views/AutoPostBoosting/AutoPostBoostingEdit.vue'),
    // component: () => import('../views/PostBoosting/PostBoostingNew.vue'),
    children: [
      {
        path: '/auto-post-boosting/:service/new/details',
        name: 'PostBoostingNewDetails',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Details',
          name: 'Details'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingDetails.vue')
        // component: () => import('../views/PostBoosting/PostBoostingDetails.vue')
      },
      {
        path: '/auto-post-boosting/:service/new/filters',
        name: 'PostBoostingNewFilters',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Filters',
          name: 'Filters'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingFilters.vue')
        // component: () => import('../views/PostBoosting/PostBoostingFilters.vue')
      },
      {
        path: '/auto-post-boosting/:service/new/:ruleId/campaign',
        name: 'PostBoostingNewCampaign',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Campaign',
          name: 'Campaign'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingCampaign.vue')
        // component: () => import('../views/PostBoosting/PostBoostingCampaign.vue')
      },
      {
        path: '/auto-post-boosting/:service/new/:ruleId/ad-set',
        name: 'PostBoostingNewAdSet',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Ad Set',
          name: 'Ad Set'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingAdSet.vue')
        // component: () => import('../views/PostBoosting/PostBoostingAdSet.vue')
      },
      {
        path: '/auto-post-boosting/:service/new/:ruleId/ad',
        name: 'PostBoostingNewAd',
        meta: {
          ...metaPostBoosting,
          title: 'Auto Post Boosting Ad',
          name: 'Ad'
        },
        component: () => import('../views/AutoPostBoosting/steps/AutoPostBoostingAd.vue')
        // component: () => import('../views/PostBoosting/PostBoostingAd.vue')
      }
    ]
  },
//   {
//     path: '/interests/manage',
//     name: 'InterestsExplorer',
//     children: [],
//     meta: {
//       title: 'Interests',
//       layout: 'main',
//       middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount, checkUses],
//       permissionCheckKey: INTERESTS_PERMISSION
//     },
//     component: () => import(/* webpackChunkName: "InterestsManage" */'../views/InterestsManage.vue')
//   },
//   {
//     path: '/interests/:id/explore',
//     name: 'Search',
//     children: [
//       {
//         path: 'search',
//         name: 'search',
//         meta: {
//           layout: 'main'
//         },
//         component: () => import(/* webpackChunkName: "InterestsExploreSearch" */'../views/InterestsExploreSearch.vue')
//       },
//       {
//         path: 'selected',
//         name: 'selected',
//         children: [
//           {
//             path: 'manage',
//             name: 'manage',
//             meta: {
//               layout: 'main'
//             },
//             component: () =>
//               import(/* webpackChunkName: "InterestsExploreSelectedManage" */'../views/InterestsExploreSelectedManage.vue')
//           },
//           {
//             path: 'layering',
//             name: 'layering',
//             meta: {
//               layout: 'main'
//             },
//             component: () =>
//               import(/* webpackChunkName: "InterestsExploreSelectedLayering" */'../views/InterestsExploreSelectedLayering.vue')
//           }
//         ],
//         meta: {
//           layout: 'main'
//         },
//         component: () => import(/* webpackChunkName: "InterestsExploreSelected" */'../views/InterestsExploreSelected.vue')
//       }
//     ],
//     meta: {
//       title: 'Interests',
//       layout: 'main',
//       middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
//       permissionCheckKey: INTERESTS_PERMISSION
//     },
//     component: () => import(/* webpackChunkName: "InterestsExplore" */'../views/InterestsExplore.vue')
//   },
  {
    path: '/registration',
    name: 'Registration',
    meta: {
      layout: 'registration'
    },
    component: () =>
      import(
        /* webpackChunkName: "Registration" */ '../views/Registration/Registration.vue'
      ),
    children: [
      {
        path: 'sign-up',
        name: 'SignUp',
        meta: {
          title: 'Sign Up',
          layout: 'registration',
          middleware: [redirectAuthorized]
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "signUp" */ '../views/Registration/SignUp.vue'
          )
      },
      {
        path: 'sign-in',
        name: 'SignIn',
        meta: {
          title: 'Sign In',
          layout: 'login',
          middleware: [redirectAuthorized]
        },
        props: route => ({ redirect: route.query.redirect }),
        component: () =>
          import(
            /* webpackChunkName: "signIn" */ '../views/Registration/SignIn.vue'
          )
      },
      {
        path: 'done',
        name: 'RegistrationDone',
        meta: {
          title: 'Registration done',
          layout: 'registration'
        },
        component: () =>
          import(
            /* webpackChunkName: "done" */ '../views/Registration/Done.vue'
          )
      }
    ]
  },
  {
    path: '/forgot_password',
    name: 'Forgot Password',
    meta: {
      title: 'Forgot Password',
      layout: 'login'
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot_password" */ '../views/Registration/ForgotPassword.vue'
      ),
    children: [
      {
        path: 'form',
        name: 'Forgot Password Form',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(
            /* webpackChunkName: "forgot_password_form" */ '../views/Registration/ForgotPasswordForm.vue'
          )
      },
      {
        path: 'sended',
        name: 'Forgot Password Sended',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(
            /* webpackChunkName: "forgot_password_form" */ '../views/Registration/ForgotPasswordSended.vue'
          )
      },
      {
        path: 'reset/:token',
        name: 'Password Reset',
        meta: {
          layout: 'login'
        },
        component: () =>
          import(
            /* webpackChunkName: "forgot_password_form" */ '../views/Registration/ForgotPasswordReset.vue'
          )
      }
    ]
  },
  {
    path: '/activate',
    name: 'Activate',
    meta: {
      title: 'Activation',
      layout: 'main',
      middleware: [auth],
      sidebarMenuVisible: false
    },
    component: () => import(/* webpackChunkName: "ActivateAccount" */'../views/Approve/ActivateAccount.vue')
  },
  {
    path: '/success',
    name: 'Success',
    meta: {
      title: 'Success',
      layout: 'empty',
      middleware: [auth]
    },
    component: () => import(/* webpackChunkName: "Success" */'../views/Approve/Success.vue')
  },
  {
    path: '/not-accepted',
    name: 'NotAccepted',
    meta: {
      title: 'Not accepted',
      layout: 'empty',
      middleware: [auth]
    },
    component: () => import(/* webpackChunkName: "NotAccepted" */'../views/Approve/NotAccepted.vue')
  },
  {
    path: '/without-ad-account',
    name: 'WithoutAdAccount',
    props: true,
    meta: {
      layout: 'main',
      middleware: [auth, emailConfirmed, checkDeletionAccount]
    },
    component: WithoutAdAccount
  },
  {
    path: '/automated_rules/:service',
    name: 'AutomatedRules',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION
    },
    props: true,
    component: () => import(/* webpackChunkName: "AutomatedRules" */'../views/Strategies/AutomatedRules.vue')
  },
  {
    path: '/automated_rules/:service/strategies',
    name: 'Strategies',
    meta: {
      title: 'Strategies',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION
    },
    props: true,
    component: () => import(/* webpackChunkName: "SelectStrategy" */'../views/Strategies/SelectStrategy.vue')
  },
  {
    path: '/automated_rules/:service/strategies/:strategyId',
    name: 'StrategiesShow',
    meta: {
      title: 'Strategies',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION
    },
    props: true,
    component: () => import(/* webpackChunkName: "ShowStrategy" */'../views/Strategies/ShowStrategy.vue')
  },
  {
    path: '/automated_rules/facebook/new',
    name: 'CreateRuleFacebook',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION,
      service: FACEBOOK_SERVICE
    },
    component: () => import(/* webpackChunkName: "Rule" */'../views/Strategies/Rule.vue'),
    props: { ruleId: null, service: 'facebook' }
  },
  {
    path: '/automated_rules/snapchat/new',
    name: 'CreateRuleSnapchat',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION,
      service: SNAPCHAT_SERVICE
    },
    component: () => import(/* webpackChunkName: "Rule" */'../views/Strategies/Rule.vue'),
    props: { ruleId: null, service: 'snapchat' }
  },
  {
    path: '/automated_rules/google/new',
    name: 'CreateRuleGoogle',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION,
      service: GOOGLE_SERVICE
    },
    component: () => import(/* webpackChunkName: "Rule" */'../views/Strategies/Rule.vue'),
    props: { ruleId: null, service: 'google' }
  },
  {
    path: '/automated_rules/tiktok/new',
    name: 'CreateRuleTiktok',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION,
      service: TIKTOK_SERVICE
    },
    component: () => import(/* webpackChunkName: "Rule" */'../views/Strategies/Rule.vue'),
    props: { ruleId: null, service: 'tiktok' }
  },
  {
    path: '/automated_rules/linkedin/new',
    name: 'CreateRuleLinkedin',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION,
      service: LINKEDIN_SERVICE
    },
    component: () => import(/* webpackChunkName: "Rule" */'../views/Strategies/Rule.vue'),
    props: { ruleId: null, service: 'linkedin' }
  },
  {
    path: '/automated_rules/twitter/new',
    name: 'CreateRuleTwitter',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION,
      service: TWITTER_SERVICE
    },
    component: () => import(/* webpackChunkName: "Rule" */'../views/Strategies/Rule.vue'),
    props: { ruleId: null, service: TWITTER_SERVICE }
  },
  {
    path: '/automated_rules/:service/:ruleId',
    name: 'EditRule',
    props: true,
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkAdAccount, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION
    },
    component: () => import(/* webpackChunkName: "Rule" */'../views/Strategies/Rule.vue')
  },
  {
    path: '/automated_rules/:service/:ruleId/logs',
    name: 'RuleLogs',
    meta: {
      title: 'Automated Rules',
      layout: 'main',
      middleware: [auth, emailConfirmed, checkPermissions, checkDeletionAccount],
      permissionCheckKey: RULES_PERMISSION
    },
    props: true,
    component: () => import(/* webpackChunkName: "RuleLogs" */'../views/Strategies/RuleLogs.vue')
  },
  {
    path: '/subscribe/success',
    name: 'SubscribeSuccess',
    meta: {
      layout: 'empty'
    },
    component: () => import(/* webpackChunkName: "Success" */'../views/Subscribe/Success.vue')
  },
  {
    path: '/subscribe/cancel',
    name: 'SubscribeCancel',
    meta: {
      layout: 'empty'
    },
    redirect: '/profile'
    // component: () => import(/* webpackChunkName: "Cancel" */'../views/Subscribe/Cancel.vue')
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  },
  {
    path: '*',
    // redirect: '/404',
    component: NotFound
  }
]

const middlewareMapper = route => {
    route.meta?.middleware &&
        (route.meta.middleware = [...route.meta.middleware, ...defaultMiddlewares])
    if (route.children) {
        route.children = route.children.map(r => middlewareMapper(r))
    }
    return route
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes.map(r => middlewareMapper(r)), ...ARMaintenanceModeRoutes]
})

const originalPush = router.push
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
router.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch(err => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }

        return Promise.reject(err)
    })
}

export interface middlewareContext {
    to: Route
    from: Route
    next: NavigationGuardNext
    store: Store<any>
    nextMiddleware?: () => void
}

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }

    const context = {
        to,
        from,
        next,
        store
    }
    return to.meta.middleware[0]({
        ...context,
        nextMiddleware: middlewarePipeline(context, to.meta.middleware, 1)
    })
})

const localStorageReleaseReloadKey = 'cb_release_reloads'

router.onError(error => {
    // bootstrap:172 Uncaught (in promise) ChunkLoadError: Loading chunk Profile failed.
    if (/loading chunk \S* failed./i.test(error.message)) {
        // if catch exception "ChunkLoadError" and app version from server != app version from vuex state, then reload page
        // before reload, check infinity reload conditions(less than 3 reload in last 5 minutes)
        store.dispatch('appConfig/getVersionFromServer').then(version => {
            if (version && version !== store.getters['appConfig/getVersion']) {
                const reloads = JSON.parse(localStorage.getItem(localStorageReleaseReloadKey))
                if (reloads && reloads.dt && reloads.count) {
                    if (Date.now() - reloads.dt > 60 * 1000 * 5) {
                        // more than 5 minutes have passed
                        localStorage.setItem(
                            localStorageReleaseReloadKey,
                            JSON.stringify({ dt: Date.now(), count: 1 })
                        )
                        window.location.reload()
                    } else if (reloads.count < 3) {
                        // less than 5 minutes have passed and reloaded less than 3
                        localStorage.setItem(
                            localStorageReleaseReloadKey,
                            JSON.stringify({ dt: reloads.dt, count: reloads.count + 1 })
                        )
                        window.location.reload()
                    }
                } else {
                    localStorage.setItem(
                        localStorageReleaseReloadKey,
                        JSON.stringify({ dt: Date.now(), count: 1 })
                    )
                    window.location.reload()
                }
            }
        })
    }
})

export default router
