














import NewUiNotifications from '@/components/NewUIComponents/NewUiNotifications.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'RegistrationLayout',
  components: { NewUiNotifications }
})
