


































































































import { POST_BOOSTING_PERMISSION } from '@/constants/UserPermissions'
import Vue from 'vue'

import Logo from './Logo.vue'
import HeaderAccountBlock from './HeaderAccountBlock.vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'TheSidebar',
  components: { Logo, HeaderAccountBlock },
  props: {
    title: String,
    menuVisible: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      menuList: [
        // {
        //   title: 'Dashboard',
        //   href: '/dashboard',
        //   icon: require('../assets/img/dashboard-color.svg'),
        //   color: '#4B74C8',
        //   submenu: []
        // },
        {
          title: 'Automated Rules',
          href: '/automated_rules',
          icon: require('../assets/img/strategies.svg'),
          color: '#FF5554',
          views: true,
          key_views: 'rules',
          submenu: [
            {
              title: 'Facebook',
              href: '/automated_rules/facebook',
              views: true,
              key_views: 'facebook',
              color: '#FF5554'
            },
            {
              title: 'Snapchat',
              href: '/automated_rules/snapchat',
              views: true,
              key_views: 'snapchat',
              color: '#FF5554'
            },
            {
              title: 'Google',
              href: '/automated_rules/google',
              views: true,
              key_views: 'google',
              color: '#FF5554'
            },
            {
              title: 'TikTok',
              href: '/automated_rules/tiktok',
              views: true,
              key_views: 'tiktok',
              color: '#FF5554'
            },
            {
              title: 'Linkedin',
              href: '/automated_rules/linkedin',
              views: true,
              key_views: 'linkedin',
              color: '#FF5554'
            },
            {
              title: 'Twitter',
              href: '/automated_rules/twitter',
              views: false,
              key_views: 'twitter',
              color: '#FF5554'
            }
          ],
          submenu_state: false
        },
        {
          title: 'Auto Post Boosting',
          href: '/auto-post-boosting',
          icon: require('../assets/img/post-boosting.svg'),
          color: '#5D81CD',
          views: true,
          key_views: POST_BOOSTING_PERMISSION,
          submenu: [
            {
              title: 'Facebook',
              href: '/auto-post-boosting/facebook',
              views: true,
              key_views: 'facebook',
              color: '#FF5554'
            }
          ],
          submenu_state: false
        },
        // {
        //   title: 'Audience manager',
        //   href: '/audience-manager',
        //   icon: require('../assets/img/audience-manager-color.svg'),
        //   color: '#FD6F6E',
        //   submenu: []
        // },
        // {
        //   title: 'Accounts',
        //   href: '/accounts',
        //   icon: require('../assets/img/accounts-color.svg'),
        //   color: '#FC9B16',
        //   submenu: []
        // },
        // {
        //   title: 'Automated Rules',
        //   href: '/automated-rules',
        //   icon: require('../assets/img/automated-rules-color.svg'),
        //   color: '#40A9DA',
        //   submenu: []
        // },
        // {
        //   title: 'Interests Explorer',
        //   href: '/interests/manage',
        //   icon: require('../assets/img/interests-color.svg'),
        //   color: '#fb9700',
        //   key_views: 'interests',
        //   submenu: [],
        //   views: true,
        //   submenu_state: false
        // },
        {
          title: 'Ads Manager',
          href: '/ads-manager',
          icon: require('../assets/img/ads-manager-color.svg'),
          color: '#8478EC',
          key_views: 'ads_manager',
          submenu: [],
          submenu_state: false,
          views: true,
          notification: 2
        },
        {
          title: 'Integrations',
          href: '/integrations',
          icon: require('../assets/img/integrations-color.svg'),
          color: '#628EE9',
          key_views: 'integrations',
          views: true,
          submenu: []
        },
        {
          title: 'Support',
          href: '/support',
          icon: require('../assets/img/tickets-color.svg'),
          color: '#11C299',
          views: true,
          submenu: []
        }
        // {
        //   title: 'FAQ (test preloader)',
        //   href: '/faq',
        //   icon: require('../assets/img/faq-color.svg'),
        //   color: '#A65BD7',
        //   submenu: []
        // }
      ]
    }
  },
  watch: {
    get_sidebar_open_state (value: boolean): void {
      if (!value) {
        document.body.classList.add('prevent-scrolling-by-menu')
      } else {
        document.body.classList.remove('prevent-scrolling-by-menu')
      }
    },
    '$route.path': {
      handler (value) {
        const item = this.findMenuItem(value)
        if (!item) {
          this.menuList.forEach((item) => {
            item.submenu_state = false
          })
        }
      }
    }
  },
  created () {
    const item = this.findMenuItem(this.$route.path)
    if (item) {
      item.submenu_state = true
    }
  },
  methods: {
    ...mapActions(['changeVision', 'setVision']),
    check_route (item) {
      return item.href.split('/')[1] === this.$route.path.split('/')[1]
    },
    findMenuItem (href: string) {
      return this.menuList.find((item) => {
        if (item.href === href) return true
        if (item.submenu.length) {
          return !!item.submenu.find((submenu) => submenu.href === href)
        }
      })
    },
    go_to (item: any, itemIndex: any) {
      /*
       * функция клика на пункт меню первого уровня, при клике закрываем все открытые меню второго уровня
       * кроме пункта меню на котором произвели клик
       *  */
      (this as any).menuList.forEach((i: any, index: number) => {
        if (index !== itemIndex) {
          i.submenu_state = false
        }
      })
      // если меню второго уровня нет
      if (item.submenu.length <= 0) {
        // редиректим по ссылке из объекта пункта меню (menuList)
        // this.$router.push(item.href)
        // если работает по правилам мобильных(планшетов) закрываем меню перед переходом на страницу
        if (this.get_width < 1440) {
          this.setVision(true)
        }
      } else {
        // если меню второго уровня есть, то редиректим на ссылку из первого элемента массива submenu
        // this.$router.push(item.submenu[0].href)
        // меняем состояние откр/закр меню второго уровня
        const menu_item = this.menuList.find(el => el.title === item.title)
        menu_item.submenu_state = true
      }
    },
    go_to_second_level () {
      // this.$router.push(submenuItem.href)
      // если работает по правилам мобильных(планшетов) закрываем меню перед переходом на страницу
      if (this.get_width < 1440) {
        this.setVision(true)
      }
    }
  },
  computed: {
    ...mapGetters(['get_sidebar_open_state', 'get_width', 'get_sidebar_views']),
    routePathName () {
      return this.$route.path
    },
    menuListFiltered () {
      return this.menuList.map(item => {
        return {
          ...item,
          submenu: item.submenu.map(sub_item => {
            // фильтрация submenu
            return {
              ...sub_item,
              views: (!this.get_sidebar_views || this.get_sidebar_views[item.key_views] === undefined || this.get_sidebar_views[item.key_views][sub_item.key_views] === undefined) ? item.views : this.get_sidebar_views[item.key_views][sub_item.key_views]
            }
          }).filter(sub_item => sub_item.views)
        }
      }).filter(item => {
        if (item.submenu.length === 0) { // для разделов без submenu, типа интеграций
          if (this.get_sidebar_views && this.get_sidebar_views[item.key_views]) {
            return Object.keys(this.get_sidebar_views[item.key_views]).filter(k => this.get_sidebar_views[item.key_views][k]).length > 0
          }
          return item.views
        } else {
          return item.submenu.filter(sub_item => sub_item.views).length > 0
        }
      })
    }
  }
})
