import { AxiosResponse } from 'axios'
import IDeleteCampaign from '@/models/AdsManager/IDeleteCampaign'
import IUpdateStatusCampaign from '@/models/AdsManager/IUpdateStatusCampaign'
import iDeleteAdSet from '@/models/AdsManager/iDeleteAdSet'
import ISaveFilterRequestBody from '@/models/AdsManager/ISaveFilterRequestBody'
import IUpdateStatusAdSet from '@/models/AdsManager/IUpdateStatusAdSet'
import http from '@/http-common'
import URI from 'urijs'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  // * Campaign
  get_campaigns (requestBody): Promise<AxiosResponse> {
  /*
    const resultValues = []

    const values = (filter, index) => {
      const str = (
        `filter[${index}][field]=${filter.field}` +
        `&filter[${index}][value]=${filter.value}` +
        `&filter[${index}][predicate]=${filter.predicate}`
      )
      const result = () => {
        if (filter?.type) return `filter[${index}][type]=${filter.type}&` + str
        else return str
      }
      if (requestBody.filter.length - 1 === index) resultValues.push(result())
      else resultValues.push(result() + '&')
    }

    if (requestBody.filter.length > 0) {
      requestBody.filter.forEach((filter, index) => values(filter, index))
    }

    const checkValues = () => {
      if (resultValues.length > 0) return '&' + resultValues.join('')
      else return ''
    }
    */

    return http.get(
    `/marketing/campaigns?page=${requestBody.page}` +
    `&ad_account_id=${requestBody?.ad_account_id || ''}` +
    '&include[]=campaign_insights&include[]=periods' +
    requestBody.filters)
  },
  // в документации запрос должен идти на /campaign-details - по факту данных не хватает
  get_campaign_detail (id) {
    return http.get(
      '/marketing/facebook/campaigns/' + id + '?include[]=budget'
    )
  },

  get_campaign_performance (id, startDate, endDate) {
    return http.get(
      '/marketing/facebook/campaigns/' +
        id +
        '/report/campaign-performance?start_date=' +
        startDate +
        '&stop_date=' +
        endDate
    )
  },
  // get_campaign_targeting (id) {
  //   return http.get('/marketing/facebook/campaigns/' + id + '/report/targeting')
  // },

  get_campaign_audience_inspector (id) {
    return http.get(
      '/marketing/facebook/campaigns/' + id + '/report/audience-inspector'
    )
  },

  get_campaign_best_period (id) {
    return http.get(
      '/marketing/facebook/campaigns/' +
        id +
        '/report/best-periods?include[]=gradient'
    )
  },

  get_campaign_top_factors (id, startDate, endDate) {
    return http.get(
      '/marketing/facebook/campaigns/' +
        id +
        '/report/top-factors?start_time=' +
        '14-08-2021' +
        '&stop_time=' +
        endDate
    )
  },
  delete_campaign (requestBody: IDeleteCampaign): Promise<AxiosResponse> {
    return http['delete']('/marketing/facebook/campaigns', {
      data: requestBody
    })
  },

  update_status_campaign (
    requestBody: IUpdateStatusCampaign
  ): Promise<AxiosResponse> {
    return http.patch('/marketing/facebook/campaigns', requestBody)
  },

  publish_campaign (campaignId: number): Promise<AxiosResponse> {
    return http.patch(`/marketing/facebook/campaigns/${campaignId}`, { status: 'publish' })
  },

  // Edit campaign
  get_campaign_ad_set (campaignId, adSetId) {
    return http.get(`/marketing/facebook/campaigns/${campaignId}/ad-sets/${adSetId}`)
  },
  save_changes_published_campaign (campaignId, data) {
    return http.patch(`/marketing/facebook/campaigns/${campaignId}`, data)
  },
  save_changes_published_ad_set (campaignId, adSetId, data) {
    return http.patch(`/marketing/facebook/campaigns/${campaignId}/ad-sets/${adSetId}`, data)
  },

  // * New campaign

  get_ad_set_design (campaignId) {
    return http.get('/marketing/facebook/campaigns/' + campaignId + '/ad-design')
  },

  get_media () {
    return http.get('/marketing/media')
  },

  save_media (data) {
    const axiosConfig = {
      headers: {
        Accept: 'application/json, text/plain, */*'
      },
      withCredentials: true
    }
    return http.post('/marketing/media', data, axiosConfig)
  },

  delete_media (data) {
    let urlArr = ''
    data.image_hashes.forEach((item, index) => {
      urlArr += index === data.image_hashes.length - 1 ? 'image_hashes[]=' + item : 'image_hashes[]=' + item + '&'
    })
    return http.delete('/marketing/media?' + urlArr)
  },

  get_pages (campaignId) {
    return http.get('/marketing/facebook/campaigns/' + campaignId + '/pages')
  },

  get_link_for_creating (payload) {
    return http.get('/marketing/facebook/campaigns/' + payload.campaignId + '/instant-forms?page_id=' + payload.pageId + '&ad_account_id=' + payload.adAccountId)
  },

  // get_link_for_creating (payload) {
  //   const access_token = window.localStorage.getItem('access_token')
  //   return fetch('https://convertbomb-api.fakebook.press/webapi/v2/marketing/fb/campaigns/' + payload.campaignId + '/instant-forms?page_id=' + payload.pageId + '&ad_account_id=' + payload.adAccountId, {
  //     redirect: 'follow',
  //     mode: 'no-cors',
  //     headers: { Authorization: 'Bearer ' + access_token, 'Access-Control-Allow-Origin': 'https://asignature.io' }
  //   })
  // },

  get_instant_forms (payload) {
    let search
    search = new URI(baseUrl + 'marketing/facebook/campaigns/' + payload.campaignId + '/instant-forms/' + payload.pageId + '?page=' + payload.page)
    if (payload.search) {
      search = search.addSearch({ search: payload.search })
    }
    return http.get(search.toString())
    // return http.get('/marketing/fb/campaigns/' + payload.campaignId + '/instant-forms/' + payload.pageId + '?page=' + payload.page, {})
  },

  get_instagram_accounts (campaignId, pageId) {
    return http.get('/marketing/facebook/campaigns/' + campaignId + '/instagram-accounts?page_id=' + pageId)
  },

  get_live_ad_preview (campaignId, data) {
    return http.post('/marketing/facebook/campaigns/' + campaignId + '/ad-previews', data)
  },

  get_ad_preview (requestBody: {campaignId: string, requestBody: {ad_id: string, ad_format: string}}): Promise<AxiosResponse> {
    return http.get(`/marketing/facebook/campaigns/${requestBody.campaignId}/ad-previews`, { params: requestBody.requestBody })
  },

  save_ad_design (data, campaignId) {
    return http.post('/marketing/facebook/campaigns/' + campaignId + '/ad-design', data)
  },
  // * AdSet

  load_allAds_adSets (payload: Record<string, any>): Promise<AxiosResponse> {
    const resultValues = []

    /*
    const values = (filter, index) => {
      const str = (
        `filter[${index}][field]=${filter.field}` +
        `&filter[${index}][value]=${filter.value}` +
        `&filter[${index}][predicate]=${filter.predicate}`
      )
      const result = () => {
        if (filter?.type) return `filter[${index}][type]=${filter.type}&` + str
        else return str
      }
      if (payload.filter.length - 1 === index) resultValues.push(result())
      else resultValues.push(result() + '&')
    }

    if (payload.filter.length > 0) {
      payload.filter.forEach((filter, index) => values(filter, index))
    }

    const checkValues = () => {
      if (resultValues.length > 0) return '&' + resultValues.join('')
      else return ''
    }
     */

    return http.get(
      `marketing/facebook/campaigns/${payload.ad_account_id}/report/ad-sets?include[]=ads&include[]=ad_set_insights&include[]=ads.ad_insights` +
      `&page=${payload.page}` +
      payload.filters
    )
  },

  delete_adSet_ad (requestBody: iDeleteAdSet): Promise<AxiosResponse> {
    return http.delete(
      `/marketing/facebook/campaigns/${requestBody.idCampaign}/report/ad-sets`,
      {
        data: requestBody.requestBody
      }
    )
  },

  update_status_adSet_ad (
    requestBody: IUpdateStatusAdSet
  ): Promise<AxiosResponse> {
    return http.patch(
      `/marketing/facebook/campaigns/${requestBody.idCampaign}/report/ad-sets`,
      requestBody.requestBody
    )
  },

  // * AdSet spend cap

  get_adSet_spendCap (requestBody): Promise<AxiosResponse> {
    return http.get(`marketing/facebook/campaigns/${requestBody.id}/ad-sets`, {
      params: requestBody.type
    })
  },

  update_adSet_spendCap (requestBody: any): Promise<AxiosResponse> {
    return http.patch(
      `marketing/facebook/campaigns/${requestBody.id}/ad-sets`,
      requestBody.requestBody
    )
  },

  get_ad_sets (campaignId): Promise<AxiosResponse> {
    return http.get(`/marketing/facebook/campaigns/${campaignId}/ad-sets`)
  },
  get_report_adSets (campaign) {
    return http.get(`/marketing/facebook/campaigns/${campaign.id}/ad-sets`)
  },
  // * filters

  get_filters (type): Promise<AxiosResponse> {
    return http.get(`marketing/filters?type=${type}`)
  },

  save_filters (requestBody: ISaveFilterRequestBody): Promise<AxiosResponse> {
    return http.post('marketing/filters', requestBody)
  },

  delete_filter (filterId: string): Promise<AxiosResponse> {
    return http['delete'](`marketing/filters/${filterId}`)
  },

  get_campaign_objective (): Promise<AxiosResponse> {
    return http.get('marketing/facebook/campaigns/objective')
  },

  get_special_ad_categories (): Promise<AxiosResponse> {
    return http.get('marketing/facebook/campaigns/special-ad-categories')
  },

  store_campaign (data, campaignId = null): Promise<AxiosResponse> {
    if (campaignId) {
      return http.patch('marketing/facebook/campaigns/' + campaignId, data)
    } else {
      return http.post('marketing/facebook/campaigns', data)
    }
  },

  // * create company budget
  get_budget (idCampaign) {
    return http.get(`/marketing/facebook/campaigns/${idCampaign}/budget`)
  },

  create_budget (requestBody) {
    return http.post(
      `/marketing/facebook/campaigns/${requestBody.id}/budget`,
      requestBody.requestBody
    )
  },

  getAttributionSpec (id) {
    return http.get(`/marketing/facebook/campaigns/${id}/attribution-spec`)
  },
  get_bidding (requestBody: { id: number, optimize: string }) {
    return http.get(`/marketing/facebook/campaigns/${requestBody.id}/bidding?optimize_for=${requestBody.optimize}`)
  },

  get_custom_audiences (campaignId): Promise<AxiosResponse> {
    return http.get(
      'marketing/facebook/campaigns/' + campaignId + '/targeting/get-custom-audiences'
    )
  },
  get_location_types (campaignId): Promise<AxiosResponse> {
    return http.get(
      'marketing/facebook/campaigns/' + campaignId + '/targeting/location-types'
    )
  },
  get_geo_locations (campaignId: number, q: string): Promise<AxiosResponse> {
    return http.get(
      'marketing/facebook/campaigns/' + campaignId + '/targeting/search-geo-location',
      { params: { q: q } }
    )
  },
  get_interests (campaignId: number, q: string): Promise<AxiosResponse> {
    return http.get(
      'marketing/facebook/campaigns/' + campaignId + '/targeting/search-interest',
      { params: { q: q } }
    )
  },
  get_reach_estimate (campaignId: number, payload): Promise<AxiosResponse> {
    return http.post(
      'marketing/facebook/campaigns/' + campaignId + '/targeting/get-reach-estimate',
      payload
    )
  },
  create_audience (campaignId: number, payload): Promise<AxiosResponse> {
    return http.post(
      'marketing/facebook/campaigns/' + campaignId + '/targeting',
      payload
    )
  },
  get_targeting (campaignId: number): Promise<AxiosResponse> {
    return http.get('marketing/facebook/campaigns/' + campaignId + '/targeting')
  },

  // Conversion Objective
  // Additional fields: Pixel, Event
  //   Get Conversion Pixels: http://example-leadenforce.local/webapi/v2/marketing/fb/campaigns/{campaign_id}/pixel-list
  get_conversion_pixel (campaignId: number): Promise<AxiosResponse> {
    return http.get('marketing/facebook/campaigns/' + campaignId + '/pixel-list')
  },
  //   Get Conversion Events:
  get_conversion_events (campaignId: number): Promise<AxiosResponse> {
    return http.get('marketing/facebook/campaigns/' + campaignId + '/conversion-events')
  },
  //   Get Ad Format: http://example-leadenforce.local/webapi/v2/marketing/fb/campaigns/{campaign_id}/ad-formats
  get_ad_format (campaignId: number, payload): Promise<AxiosResponse> {
    return http.post('marketing/facebook/campaigns/' + campaignId + '/ad-formats', payload)
  },
  // Creative / docs: https://git.ds-in.net/fakebook/fakebook-mini/-/blob/cb_dev/docs/api/v2/marketing/fb/creative.md
  get_ads_creative (data: { campaign_id: string, ad_id: string, includes: string[] | undefined }): Promise<Response> {
    let includes = ''

    if (data.includes) {
      let index = 0
      includes += '?'
      for (const include of data.includes) {
        if (index++) {
          includes += '&'
        }
        includes += `include[${index}]=${include}`
      }
    }
    return http.get(`marketing/facebook/campaigns/${data.campaign_id}/ads/${data.ad_id}${includes}`)
  },
  update_ads_creative (data: { campaign_id: string, ad_id: string, body: any }): Promise<Response> {
    return http.post(`marketing/facebook/campaigns/${data.campaign_id}/ads/${data.ad_id}`, data.body)
  }
}
