







import Vue from 'vue'
import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import RegistrationLayout from '@/layouts/RegistrationLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import { mapActions, mapGetters } from 'vuex'
export default Vue.extend({
  name: 'App',
  components: {
    MainLayout,
    EmptyLayout,
    RegistrationLayout,
    LoginLayout
  },
  metaInfo () {
    return {
      meta: [
        {
          name: 'facebook-domain-verification',
          content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION || '123'
        },
        {
          name: 'viewport',
          content: this.viewport
        }
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        document.title = to.meta.title || 'AutomatedRules'
      }
    }
  },
  created () {
    // this.getProfile().then(() => {
    //   if (this.is_authorized) {
    //     this.loadAccounts({
    //       search: { service: 'facebook', target: 'ads_management' }
    //     })
    //   }
    // })
  },
  computed: {
    ...mapGetters('auth', ['is_authorized']),
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    sidebarMenuVisible () {
      return this.$route.meta.sidebarMenuVisible
    },
    viewport () {
      return this.$route.meta?.remove_viewport ? 'width=device-width,initial-scale=0.5' : 'width=device-width,initial-scale=1.0'
    }
  },
  methods: {
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('profile', ['getProfile'])
  }
})
